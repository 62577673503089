export default {
  default: {
    slidesPerView: 1.2,
    spaceBetween: 32,
    breakpoints: {
      640: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 2.5,
      },
      1024: {
        slidesPerView: 3,
      },
    },
  },
};
